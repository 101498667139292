import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-grid-system';
import { HeroPatients } from 'components/hero';

const TermsOfUsePage = () => {
	return (
		<Layout indication='patient' className='patient-terms-of-use'>
			<Seo pageTitle='Terms of Use' />
			<div className='body-content-container'>
				<div className='body-content'>
					<HeroPatients heroImgAlt='page hero' className='sub-page-hero'>
						<h1>Terms of Use</h1>
					</HeroPatients>
					<section>
						<Row align='center'>
							<Col xs={12}>
								<div className='content'>
									<ol className='numerals-list'>
										<li>
											<p>
												<span className='text-bold'>
													"Purdue," as used in this website, shall mean Purdue
													Pharma L.P. and U.S. associated companies.
												</span>
											</p>
										</li>
										<li>
											<p>
												COPYRIGHT AND TRADEMARK NOTICE
											</p>
											<p>
												The entire content of this Internet Website ("Site") is
												fully protected under all applicable copyright and
												trademark laws. Users may print a single copy of the
												content of this Site for their personal noncommercial
												use only. Further printing, copying, redistribution or
												publication of any part of this Site is strictly
												prohibited unless expressly permitted within.
											</p>
										</li>
										<li>
											<p>
												RESTRICTIONS ON USING THE CONTENT OF THIS WEBSITE
											</p>
											<p>
												Purdue makes no representation regarding the
												availability, the accuracy, or the completeness of the
												content of this Site. The Site contains information
												about products that may or may not be available in a
												particular country or region of the world, may be
												available under different trademarks in different
												countries and, where applicable, may be approved by
												government regulatory authority for sale or use with
												different indications and restrictions in different
												countries.
											</p>
											<p>
												This Site may contain, from time to time, information
												related to various health, medical and fitness
												conditions and their treatment. For non-healthcare
												providers, such information is not intended to be a
												substitute for the advice provided by a physician or
												other medical professional. You should not use the
												information contained herein for diagnosing a health or
												fitness problem or disease. In any event, users of this
												Site should always consult a doctor and/or other medical
												professional with respect to health concerns.
											</p>
											<p>
												While Purdue uses reasonable efforts to include accurate
												and up-to-date information in its Site, Purdue makes no
												warranties or representations as to its accuracy. Purdue
												assumes no liability or responsibility for any errors or
												omissions in the content of its Site. This Site may
												provide links or references to other Websites not
												affiliated with Purdue. Such links to other Sites are
												provided only as a convenience to users of this Site.
												Purdue has not reviewed all of the Sites that may be
												linked to its Site, and is not responsible for the
												content of any other Site linked to this Site. Linking
												to any pages off this Site is at your own risk. Purdue
												shall not be liable for any damages or injury arising
												from users’ access to such Sites.
											</p>
										</li>
										<li>
											<p>
												If in the event the Site provides visitors with an
												opportunity to post messages, you shall not post or
												transmit any unlawful, threatening, libelous,
												defamatory, obscene, inflammatory, pornographic or
												profane material or material that could constitute or
												encourage conduct that would be considered a criminal
												offense, give rise to civil liability, or would
												otherwise violate the law. Purdue shall not be
												responsible for content posted to its site by third
												parties.
											</p>
										</li>
										<li>
											<p>LIMITATION OF LIABILITY</p>
											<p>
												Purdue provides the contents of its Site for
												informational purposes and for your general interest and
												entertainment only. By using the Site you hereby agree
												not to rely on any of the information contained herein.
												Under no circumstances shall Purdue be liable for your
												reliance on any such information nor shall Purdue be
												liable for damages of any kind, including, without
												limitation, any direct, incidental, special
												consequential, indirect or punitive damages that result
												from the use of, or the inability to use, the materials
												in this Site or the materials in any Site which may be
												linked to this Site, even if Purdue or a Purdue
												authorized representative has been advised of the
												possibility of such damages. Applicable law may not
												allow the limitation or exclusion of liability or
												incidental or consequential damages, so the above
												limitation or exclusion may not apply to you. In no
												event shall Purdue’s total liability to you for all
												damages, losses, and causes of action whether the cause
												of action is in contract, tort (including, but not
												limited to, negligence) or otherwise exceed the amount
												paid by you, if any, for accessing this Site.
											</p>
										</li>
										<li>
											<p>DISCLAIMER OF WARRANTIES</p>
											<p>
												THE MATERIALS IN THIS SITE ARE PROVIDED "AS IS" AND
												WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR
												IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
												APPLICABLE LAW, PURDUE DISCLAIMS ALL WARRANTIES, EXPRESS
												OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
												WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
												PARTICULAR PURPOSE. PURDUE DOES NOT WARRANT THAT THE
												FUNCTIONS CONTAINED IN THE MATERIALS WILL BE
												UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
												CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT
												AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
												COMPONENTS. PURDUE DOES NOT WARRANT OR MAKE ANY
												REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE
												USE OF THE MATERIALS IN THESE SITES IN TERMS OF THEIR
												CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU
												(AND NOT PURDUE) ASSUME THE ENTIRE COST OF ALL NECESSARY
												SERVICING, REPAIR OR CORRECTION. APPLICABLE LAW MAY NOT
												ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
												EXCLUSION MAY NOT APPLY TO YOU.
											</p>
										</li>
										<li>
											<p>
												JURISDICTIONAL ISSUES/EXPORT RESTRICTIONS
											</p>
											<p>
												This Site is controlled and operated by Purdue from its
												offices within the State of Connecticut, United States
												of America. Although the Internet is global in nature,
												the laws regulating the manner in which pharmaceutical
												companies convey medical or product information is not.
												Each country has its own set of rules, regulations and
												laws. As a result, information intended for
												dissemination in a particular country may not be
												appropriate for use outside that country. The
												information on this Site is designed to comply with the
												laws of the United States. The information on this Site
												is intended for access and use by U.S. residents only.
												This Agreement shall be governed by and construed in
												accordance with the laws of the State of Connecticut,
												without giving effect to any principles of conflicts of
												law. If any provision of this Agreement shall be
												unlawful, void, or for any reason unenforceable, then
												that provision shall be deemed severable from this
												Agreement and shall not affect the validity and
												enforceability of any remaining provisions. This is the
												entire Agreement between the parties relating to the
												subject matter herein.
											</p>
											<p>
												This Site may link to other Sites produced by companies
												related to Purdue, some of which are outside the U.S.
												Those Sites may have information that is appropriate
												only to the particular originating country or region
												where the Site is based. You should not construe
												anything on the Site as a promotion or solicitation for
												any product or for the use of any product that is not
												authorized by the laws and regulations of the country
												where you are located. Purdue makes no representation
												that materials in the Site are appropriate or available
												for use in other locations. Those who choose to access
												this Site from other locations do so on their own
												initiative and are responsible for compliance with local
												laws, if and to the extent local laws are applicable.
											</p>
											<p>
												Software from this Site is further subject to United
												States export controls. No software from this Site may
												be downloaded or otherwise exported or re-exported (i)
												into (or to a national or resident of) Cuba, Iraq,
												Libya, North Korea, Iran, Syria or any other country
												subject to United States export control restrictions; or
												(ii) to anyone on the U.S. Treasury Department’s list of
												Specially Designated Nationals or the U.S. Commerce
												Department’s Table of Denial Orders. By downloading or
												using the Software, you represent and warrant that you
												are not located in, under the control of, or a national
												or resident of any such country or on any such list.
											</p>
										</li>
										<li>
											<p>CAREER OPPORTUNITIES</p>
											<p>
												The Site displays information about job postings
												("Current Openings") for informational purposes only.
												This information is subject to change without notice,
												and in displaying such information Purdue makes no
												representation or warranty that the information
												contained on the Site will be timely or free of errors
												and omissions.
											</p>
											<p>
												It is the intent of Purdue to leave you with absolute
												control over the decision to forward your personal
												information or resume to the company. It is your choice
												to give Purdue your personal information via the
												Internet, Fax or Mail.
											</p>
											<p>
												Under no circumstances, should you use this Site or any
												of the fax and mailing addresses on the web site to send
												Purdue confidential or proprietary information. Purdue
												does not want to receive such confidential or
												proprietary information. Data which is not personal,
												including questions, comments, ideas, and suggestions
												shall be deemed to be non-confidential, and Purdue shall
												be free to disclose and use the same, without
												obligations whatsoever towards you, for any purpose and
												through any means.
											</p>
											<p>
												Although accessible by others, the intent of Purdue in
												displaying Current Openings on this Site is to display
												employment opportunities within the United States of
												America. You should not construe anything on the Site as
												a promotion or solicitation for employment not
												authorized by the laws and regulations of the country
												where you are located.
											</p>
										</li>
										<li>
											<p>LINKING POLICY</p>
											<p>
												Links Out — This Site may provide links or references to
												other Websites not affiliated with Purdue. Purdue has
												not reviewed the content of Websites that may be linked
												to its Site, makes no representations about the content
												of the Websites, and is not responsible for the content
												of any other Website linked to this Site. Linking to any
												pages off this Site is at your own risk. Purdue shall
												not be liable for any damages or injury arising from
												users' access to such Websites.
											</p>
											<p>
												Links In — Unless otherwise set forth in a written
												agreement between you and Purdue, you must adhere to
												Purdue's linking policy as follows: i) any link to a
												Purdue Site must be a text only link clearly marked
												"Purdue Web Site", ii) the appearance, position and
												other aspects of the link may not be such as to damage
												or dilute the goodwill associated with Purdue's name or
												trademarks, iii) the link must point to one of the
												specific URLs listed below:
											</p>
											<p>
												<a href='/'>www.OxyContin.com</a>
											</p>
											<p>
												and not to other pages within the website, iv) the
												appearance, position and other attributes of the link
												may not create the false appearance that your
												organization or entity is sponsored by, associated with,
												or affiliated with Purdue or the Site, v) when selected
												by the user, the link must display the Website on full
												screen and not within a "frame" on the linking Website,
												and vi) the linked Website must comply with all
												applicable U.S. laws, rules, and regulations.
											</p>
										</li>
									</ol>
								</div>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default TermsOfUsePage;
